<template>
  <el-card id="departNums">
    <header>
      <h1>部门公海</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>号码</span>
            <el-input
              v-model="activePhone"
              placeholder="请输入号码"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>企业</span>
            <el-input
              v-model="company"
              placeholder="请输入企业名称"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>行业</span>
            <el-input
              v-model="industry"
              placeholder="请输入行业"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户来源</span>
            <el-input
              v-model="customerSource"
              placeholder="请输入客户来源"
              size="small"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>备注</span>
            <el-input
              v-model="remarkSearch"
              placeholder="请输入备注"
              size="small"
            ></el-input>
          </div>
          <div
            class="left-wrap"
            v-if="roleName == '管理员' || roleName == '企业'"
          >
            <span>部门</span>
            <el-select
              v-model="departId"
              placeholder="请选择部门"
              filterable
              size="small"
            >
              <el-option
                v-for="item in underDepart"
                :key="item.id"
                :label="item.departName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>状态</span>
            <el-select
              v-model="value"
              placeholder="请选择状态"
              filterable
              size="small"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="left-wrap">
            <span>导入时间</span>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>修改时间</span>
            <el-date-picker
              v-model="changeTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
          <div class="left-wrap">
            <span>分配时间</span>
            <el-date-picker
              v-model="getTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="small"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="small"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            class="reset"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="small"
            >重置</el-button
          >
          <el-button
            type="primary"
            @click="distributeNums()"
            size="small"
            class="distributeNums"
            v-if="roleName != '人员'"
            >分配号码</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-folder-opened"
            @click="allNums()"
            size="small"
            class="allNums"
            v-if="roleName != '人员'"
            >批量导入号码</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-folder-opened"
            @click="addNums()"
            size="small"
            class="addNums"
            v-if="roleName != '人员'"
            >添加号码</el-button
          >
          <el-button
            type="danger"
            @click="moreDelete()"
            class="delete"
            size="small"
            icon="el-icon-delete"
            v-if="roleName != '人员'"
            >批量释放</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userRealname"
          label="归属人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="activePhone"
          label="手机号码"
          align="center"
          v-if="(!hideNum && id != 5051) || roleName == '企业'"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号码"
          align="center"
          v-if="(hideNum || id == 5051) && roleName != '企业'"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerPhone"
          label="姓名"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="industry"
          label="行业"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="导入时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="changeTime"
          label="修改时间"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="remarks"
          label="备注"
          align="center"
          class="hide"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0">一般客户</span>
            <span v-if="scope.row.status == 1">意向客户</span>
            <span v-if="scope.row.status == 2">无意向客户</span>
            <span v-if="scope.row.status == 3">成交客户</span>
            <span v-if="scope.row.status == 4">黑名单</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="remark"
          label="备注"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="choosePeople"
          label="决策人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerScale"
          label="公司规模"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerSource"
          label="客户来源"
          align="center"
        ></el-table-column>
        <el-table-column
          label="操作"
          width="300"
          align="center"
          fixed="right"
          v-if="roleName != '人员'"
        >
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="small"
              >编辑</el-button
            >
            <!-- 释放号码 -->
            <el-button
              type="danger"
              @click="removeButton(scope.row, scope.$index)"
              size="small"
              >释放</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200,300]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
    <!-- 释放弹出框 -->
    <el-dialog title="提示" v-model="removeVisible" width="30%">
      <span>确定移动到公司公海吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="removeVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitRemoveValue()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" :visible.sync="updateVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.phone"
            placeholder="请输入客户号码"
            v-if="(hideNum || id == 5051) && roleName != '企业'"
          ></el-input>
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
            v-if="(!hideNum && id != 5051) || roleName == '企业'"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="form.company"
            placeholder="请输入公司名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updateVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加号码弹出框 -->
    <el-dialog title="添加号码" :visible.sync="addOneVisible">
      <el-form ref="form" :model="form" label-width="100px" class="update">
        <el-form-item label="客户号码">
          <el-input
            v-model="form.activePhone"
            placeholder="请输入客户号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input
            v-model="form.customerPhone"
            placeholder="请输入客户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-input
            v-model="form.departName"
            placeholder="输入部门名称分配至部门海"
          ></el-input>
        </el-form-item>
        <el-form-item label="决策人">
          <el-input
            v-model="form.choosePeople"
            placeholder="请输入决策人"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司规模">
          <el-input
            v-model="form.customerScale"
            placeholder="请输入公司规模"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户来源">
          <el-input
            v-model="form.customerSource"
            placeholder="请输入客户来源"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业">
          <el-input v-model="form.industry" placeholder="请输入行业"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="update_status" style="line-height: 30px">
            <el-radio :label="0">一般客户</el-radio>
            <el-radio :label="1">意向客户</el-radio>
            <el-radio :label="2">无意向客户</el-radio>
            <el-radio :label="3">成交客户</el-radio>
            <el-radio :label="4">黑名单</el-radio>
            <el-radio :label="5">无状态</el-radio>
            <el-radio :label="6">未接通</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="addOneVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addOneSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量释放弹出框 -->
    <el-dialog title="提示" :visible.sync="allRemoveVisible" width="30%">
      <span>确定要释放所选内容吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allRemoveVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitDeleteList()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 批量导入号码弹出框 -->
    <el-dialog title="批量导入号码" :visible.sync="numShow" width="30%">
      <div class="content">
        <el-button
          type="success"
          icon="el-icon-download"
          @click="upload()"
          class="uploadBtn"
          >点击下载模板</el-button
        >
        <!-- <a
          style="
            height: 40px;
            background-color: #67c23a;
            border-radius: 5px;
            color: #ffffff;
            line-height: 40px;
            text-align: center;
          "
          href="http://113.250.189.239:9005/phone.xls"
          target="_blank"
          >点击下载模板</a
        > -->

        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="numShow = false">取 消</el-button>
          <el-button @click="numsSubmit" :disabled="disabled" type="primary"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 分配号码弹出框 -->
    <el-dialog title="分配号码" :visible.sync="distributeShow" width="30%">
      <el-switch
        v-model="departUser"
        active-text="选择个人"
        inactive-text="选择部门"
        v-if="roleName != '组长'"
      >
      </el-switch>
      <el-form
        ref="form"
        :model="form"
        label-width="100px"
        style="margin-top: 30px"
      >
        <el-form-item label="部门" v-if="roleName != '组长'">
          <el-select
            v-model="departId"
            placeholder="转移至其他部门公海"
            filterable
            :disabled="departUser"
            v-if="roleName != '组长'"
          >
            <el-option
              v-for="item in underDepart"
              :key="item.id"
              :label="item.departName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="个人">
          <el-select
            v-model="userId"
            placeholder="请选择个人"
            filterable
            :disabled="!departUser"
          >
            <el-option
              v-for="item in underUser"
              :key="item.id"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="nextSubmit">下一步</el-button>
          <el-button type="primary" @click="distributeShow = false"
            >退 出</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 选择分配路径弹出框 -->
    <el-dialog title="选择分配路径" :visible.sync="taskShow" width="30%">
      <span>是否将号码分配到呼叫任务</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="distributeSubmit"
            >不分配到呼叫任务</el-button
          >
          <el-button type="primary" @click="taskSubmit"
            >分配到呼叫任务</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 呼叫任务弹出框 -->
    <el-dialog title="呼叫任务" :visible.sync="taskCall" width="30%">
      <el-form ref="form" :model="taskForm" label-width="100px">
        <el-form-item label="任务名称">
          <el-input
            v-model="taskForm.taskName"
            placeholder="请输入任务名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务时间">
          <el-date-picker
            v-model="taskForm.taskTime"
            type="datetime"
            placeholder="选择成交时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="taskCall = false">取消</el-button>
          <el-button type="primary" @click="taskCallBtn">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneFindUser,
  phoneList,
  phoneDistribute,
  phoneDelete,
  phoneUpdate,
  phoneImportPhone,
  phoneAdd,
} from "@/api/phone";

export default {
  data() {
    return {
      remarkSearch:null,
      update_status: null,
      taskForm: {},
      taskCall: false,
      taskShow: false,
      choosePeople: null,
      customerScale: null,
      customerSource: null,
      id: "",
      oneNum: 0,
      twoNum: 0,
      activePhone: null,
      company: null,
      value: null,
      disabled: false,
      userId: null,
      industry: null,
      address: null,
      options1: [
        {
          value: 0,
          label: "一般客户",
        },
        {
          value: 1,
          label: "意向客户",
        },
        {
          value: 2,
          label: "无意向客户",
        },
        {
          value: 3,
          label: "成交客户",
        },
        {
          value: 4,
          label: "黑名单",
        },
        {
          value: 5,
          label: "无状态",
        },
        {
          value: 6,
          label: "未接通",
        },
      ],
      createTime: null,
      changeTime: null,
      getTime: null,
      allRemoveVisible: false, //批量释放弹出框
      removeIndex: [],
      removeVisible: false, //释放弹出框
      removeValue: "", // 释放选择框选中的项
      removeParams: {}, //释放列表时需要的参数
      updateVisible: false, //编辑弹出框
      addOneVisible: false, //添加弹出框
      distributeShow: false, //分配弹出框
      departUser: true,
      departId: null,
      departShow: true,
      form: {
        phone: null,
        choosePeople: null,
        customerScale: null,
        customerSource: null,
        createTime: null,
        changeTime: null,
        getTime: null,
        activePhone: "",
        customerPhone: "",
        company: null,
        departName: null,
        remarks: "",
        status: "",
        userId: "",
        departId: "",
        id: "",
        industry: null,
        address: null,
        roleId: "",
      },
      nums: [
        {
          id: 0,
          numName: "部门公海",
        },
        {
          id: 1,
          numName: "公司公海",
        },
      ],
      changeNums: null,
      numsArray: [], //选中号码的ID
      underDepart: [],
      underUser: [],
      remarks: "", //备注（只传不回显）
      numShow: false, //批量导入弹出框
      realname: null,
      indexArr: [],
      isBtn: false,
      hideNum: false,
      roleName: null,
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.$nextTick(function () {
      let bool = localStorage.getItem("hideNum");
      bool == "false" ? (this.hideNum = false) : (this.hideNum = true);
      console.log("hideNum参数", this.hideNum);
    });
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id") * 1;
    if (this.roleName == "管理员" || this.roleName == "企业") {
      this.findUser();
    }
    this.getList();
  },
  watch: {
    //点击部门和人员切换按钮时，清空绑定值
    departUser() {
      this.departId = null;
      this.userId = null;
    },
    tableData: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$nextTick(() => {
            //newVal是新的表格数据，[8] 8是表格数据要默认选中的索引
            this.indexArr.forEach((item) => {
              this.$refs.multipleTable.toggleRowSelection(newVal[item], true);
            });
          });
        }
      },
      // immediate: true
    },
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 分配到呼叫任务
    taskCallBtn() {
      let taskTime = this.getYMDHMS(
        "YYYY-mm-dd HH:MM:SS",
        this.taskForm.taskTime
      );
      let params = {
        taskName: this.taskForm.taskName,
        taskType: 1,
        listId: this.numsArray,
        userId: this.userId,
        taskTime,
      };
      // console.log("params", params);
      let res = phoneDistribute(params);
      res.then((res) => {});
      this.taskCall = false;
      this.distributeShow = false;
      this.taskShow = false;
      this.getList();
    },
    // 分配到呼叫任务弹出框
    taskSubmit() {
      this.taskCall = true;
    },
    // 下一步
    nextSubmit() {
      this.taskShow = true;
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray)
    },
    //查询用户下所有员工
    findUser() {
      let params = {};
      let res = phoneFindUser(params);
      res.then((res) => {
        this.underDepart = res.data.data.depart;
        this.underUser = res.data.data.user;
      });
    },

    // 补0
    fillZero(n) {
      return n <= 9 ? "0" + n : n;
    },

    // 获取年月日的函数
    getYmd(data) {
      let y = data.getFullYear(); //年
      let m = data.getMonth() + 1; //月
      let d = data.getDate(); //日
      return [y, this.fillZero(m), this.fillZero(d)].join("-");
    },

    // 获取时间的函数
    getHms(times) {
      let h = times.getHours(); //时
      let m = times.getMinutes(); //分
      let s = times.getSeconds(); //秒
      return [this.fillZero(h), this.fillZero(m), this.fillZero(s)].join(":");
    },

    // 批量释放
    moreDelete() {
      // console.log(this.tableData);
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }

      this.allRemoveVisible = true;
    },

    // 确认批量释放
    submitDeleteList() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法释放");
            return;
          }
        });
      });
      // console.log(this.tableData[0].roleId);
      let params = {
        listId: this.numsArray,
        roleId: this.tableData[0].roleId,
        departId: "",
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("释放成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.allRemoveVisible = false;
      });
    },

    // 释放按钮
    removeButton(data, index) {
      if (data.status == 3) {
        Message.warning("此号码为成交客户，无法释放");
        return;
      } else if (data.status == 4) {
        Message.warning("此号码为黑名单，无法释放");
        return;
      }
      this.removeIndex = index;
      let params = {
        listId: [data.id],
        roleId: data.roleId,
        departId: "",
      };
      this.removeParams = params;
      this.removeVisible = true;
    },
    // 释放确认事件
    submitRemoveValue() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("释放成功");
        this.tableData.splice(this.removeIndex, 1);
        this.removeVisible = false;
      });
    },

    //搜索
    handleSearch() {
      // this.getList();
      let obj = {};
      let obj1 = {};
      let obj2 = {};
      let sta_createTime = null;
      let end_createTime = null;
      if (this.createTime) {
        sta_createTime =
          this.getYmd(this.createTime[0]) +
          " " +
          this.getHms(this.createTime[0]);
        end_createTime =
          this.getYmd(this.createTime[1]) +
          " " +
          this.getHms(this.createTime[1]);
      }

      let sta_changeTime = null;
      let end_changeTime = null;
      if (this.changeTime) {
        sta_changeTime =
          this.getYmd(this.changeTime[0]) +
          " " +
          this.getHms(this.changeTime[0]);
        end_changeTime =
          this.getYmd(this.changeTime[1]) +
          " " +
          this.getHms(this.changeTime[1]);
      }

      let sta_getTime = null;
      let end_getTime = null;
      if (this.getTime) {
        sta_getTime =
          this.getYmd(this.getTime[0]) + " " + this.getHms(this.getTime[0]);
        end_getTime =
          this.getYmd(this.getTime[1]) + " " + this.getHms(this.getTime[1]);
      }
   
      if(this.remarkSearch){
        params.remarks = this.remarkSearch
      }
      let params = {};
      this.page = 1;
      // console.log('>>>>>',getList);
      params = {
        page: this.page,
        size: this.size,
        departId: 0,
        activePhone: this.activePhone,
        depart_id: this.departId,
        company: this.company,
        status: this.value,
        sta_createTime: sta_createTime,
        end_createTime: end_createTime,
        sta_changeTime: sta_changeTime,
        end_changeTime: end_changeTime,
        sta_getTime: sta_getTime,
        end_getTime: end_getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
        industry: this.industry,
        address: this.address,
      };
      let res = phoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
      });
    },
    //重置
    reset() {
      this.industry = null;
      this.customerSource = null;
      this.createTime = null;
      this.changeTime = null;
      this.getTime = null;
      this.activePhone = null;
      this.userId = null;
      this.departId = null;
      this.value = null;
      this.changeNums = null;
      this.company = null;
      this.getList();
    },
    //分配号码
    distributeNums() {
      this.findUser();
      this.departId = null;
      this.userId = null;
      this.distributeShow = true;
    },
    //分配号码确认提交
    distributeSubmit() {
      if (this.userId == "" || this.userId == null) {
        let params = {
          listId: this.numsArray,
          departId: this.departId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.getList();
          } else {
            Message.error(res.data.message);
          }
          this.distributeShow = false;
          this.getList();
        });
      } else {
        let params = {
          listId: this.numsArray,
          userId: this.userId,
        };
        let res = phoneDistribute(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
          } else {
            Message.error(res.data.message);
          }
          this.distributeShow = false;
          this.getList();
        });
      }
    },
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        departId: 0,
        activePhone: this.activePhone,
        depart_id: this.departId,
        company: this.company,
        status: this.value,
        createTime: this.createTime,
        changeTime: this.changeTime,
        getTime: this.getTime,
        choosePeople: this.choosePeople,
        customerScale: this.customerScale,
        customerSource: this.customerSource,
        industry: this.industry,
        address: this.address,
      };
      let res = phoneList(params);
      res.then((res) => {
        res.data.data.forEach((item) => {
          let str = item.activePhone;
          item.phone = str.substring(0, 3) + "****" + str.substring(7);
        });
        (this.tableData = res.data.data), (this.pageTotal = res.data.total);
      });
    },
    //编辑按钮
    update(row) {
      this.updateVisible = true;
      this.form = row.row;
      this.update_status = null;
    },
    //编辑提交
    updateSubmit() {
      if (this.update_status == null) {
        Message.warning("请选择状态!!!");
      } else {
        let params = {
          activePhone: this.form.activePhone,
          customerPhone: this.form.customerPhone,
          company: this.form.company,
          remarks: this.form.remarks,
          status: this.update_status,
          userId: this.form.userId,
          departId: this.form.departId,
          id: this.form.id,
          roleId: this.form.roleId,
          choosePeople: this.form.choosePeople,
          customerScale: this.form.customerScale,
          customerSource: this.form.customerSource,
          industry: this.form.industry,
          address: this.form.address,
        };
        let res = phoneUpdate(params);
        res.then((res) => {
          if (res.data.statusCode == "00000") {
            Message.success(res.data.message);
            this.updateVisible = false;
            this.getList();
          } else {
            Message.error(res.data.message);
            this.updateVisible = false;
            this.getList();
          }
        });
      }
    },
    //下载模板
    upload() {
      window.open("http://113.250.189.239:9005/phone.xls");
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      Message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    //批量导入按钮
    allNums() {
      this.disabled = false;
      this.numShow = true;
      this.formDate = null;
    },
    //批量导入提交
    numsSubmit() {
      this.numShow = false;
      this.disabled = true;
      let formDate = new FormData();
      Message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      let res = phoneImportPhone(formDate);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.data);
          this.file = {};
        } else {
          Message.error(res.data.data);
          this.file = {};
        }
      });
    },
    //添加号码
    addNums() {
      this.form = [];
      this.addOneVisible = true;
    },
    //添加号码确认
    addOneSubmit() {
      let params = {
        activePhone: this.form.activePhone,
        customerPhone: this.form.customerPhone,
        departName: this.form.departName,
        remarks: this.form.remarks,
        status: this.update_status,
        userId: this.id,
        choosePeople: this.form.choosePeople,
        customerScale: this.form.customerScale,
        customerSource: this.form.customerSource,
        industry: this.form.industry,
        address: this.form.address,
      };
      let res = phoneAdd(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.addOneVisible = false;
        } else {
          Message.error(res.data.message);
          this.addOneVisible = false;
        }
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
#departNums {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .uploadBtn {
    width: 68%;
  }
  /deep/.el-dialog {
    width: 70%;
  }
  .update {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    .el-form-item {
      width: 50%;
    }
  }
}
</style>